import styled from 'styled-components';

export const BreadCrumbWrapper = styled.div`
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 800;
  font-size: 1rem;
  margin: 50px 0 0 0;
  padding: 0;

  .divider {
    margin: 0 10px 0 10px;
  }

  span:hover {
    color: #d22e26;
  }
  .metabox {
  background-color: beige;
  border-radius: 3px;
  padding: 10px 15px;
  display: inline-block;
  margin-bottom: 30px;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, .07);

  &--position-up {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }

  &--with-home-link {
    padding: 0;
  }

  &__main {
    padding: 10px 15px 10px 11px;
  }

  p {
    margin: 0;
    font-size: .9rem;
    color: color(beige l(-45%) s(-33%));
  }

  a {
    color: color(beige l(-45%) s(-33%));
    text-decoration: none;
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }

  & &__blog-home-link {
    background-color: blue;
    color: #FFF;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 3px 0 0 3px;
    font-weight: normal;
  }

  & &__blog-home-link:hover {
    text-decoration: none;
    background-color: color(blue l(-5%));
  }
  }
`;
