import React from 'react';
import { Link } from 'gatsby';
import { PropTypes } from 'prop-types';

import { BreadCrumbWrapper } from './styles/BreadCrumbStyles';

const BreadCrumb = ({ parent }) => (
  <div className="container">
    <div className="row">
      <div className="col-lg-9 offset-lg-3">
        <BreadCrumbWrapper>
          <div className="metabox metabox--position-up metabox--with-home-link">
          {/*<Link to="/">*/}
          {/*  <span>Home</span>*/}
          {/*</Link>*/}
          {/*<span className="divider">/</span>*/}
          {parent ? (
            <>
              <div className="metabox__blog-home-link">
              <Link to={parent.link}>
                <span dangerouslySetInnerHTML={{ __html: parent.title }} />
              </Link></div>
              {/*<span className="divider">/</span>*/}
            </>
          ) : null}
      </div>
        </BreadCrumbWrapper>
      </div>
    </div>
  </div>
);

BreadCrumb.propTypes = {
  parent: PropTypes.object,
};

export default BreadCrumb;

