/* eslint-disable react/no-danger */
import React from "react"
import Layout from "../components/layout"
import Breadcrumb from "../components/BreadCrumb"
import { PageContent } from "./styles/archiveStyles"
import { Metabox } from "../components/styles/NewsHeadingStyles"
import { format } from "date-fns"
import PageHeroLive from '../components/PageHeroLive'

const postTemplate = ({location})=> {
  const media = typeof location.state !== 'undefined' ? location.state.media : null;
  const author = typeof location.state !== 'undefined' ? location.state.author : null;
  const title = typeof location.state !== 'undefined' ? location.state.title : null;
  const date = typeof location.state !== 'undefined' ? location.state.date : null;
  const category = typeof location.state !== 'undefined' ? location.state.category : null;
  const content = typeof location.state !== 'undefined' ? location.state.content : null;
  return (
  <Layout>
    {media ? (
      <PageHeroLive
        img={media}
        title={title}
      />
    ) : null}
    <Breadcrumb parent={{ link: "/news", title: 'News' }} />
    <h1>post</h1>
    <div className="container">
      <div className="row" style={{ marginBottom: '40px' }}>
        <PageContent className="col-lg-9">
          <Metabox>
            <p>Posted by {author} on {format(new Date(date), 'd MMM yyyy')} in {category}</p>
          </Metabox>
          <div dangerouslySetInnerHTML={{__html: content}} />
          </PageContent>
      </div>
    </div>
  </Layout>
)}

export default postTemplate
